import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TestView from '../views/TestView.vue'
import UserCenterView from '../views/UserCenterView.vue'
import loginView from '../views/loginView.vue'
import TestResultView from '../views/TestResultView.vue'
import TestResultDetailView from '../views/TestResultDetailView.vue'
import PapersView from '../views/PapersView.vue'
import HomeWorkView from '../views/HomeWorkView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '数学超哥'
    },
    component: HomeView
  },
  {
    path: '/papers',
    name: 'papers',
    meta: {
      title: '试卷列表'
    },
    component: PapersView
  },
  {
    path: '/homework',
    name: 'homework',
    meta: {
      title: '课后作业'
    },
    component: HomeWorkView
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      title: '评估'
    },
    component: TestView
  },
  {
    path: '/result',
    name: 'result',
    meta: {
      title: '评估结果'
    },
    component: TestResultView
  },
  {
    path: '/resultdetail',
    name: 'resultdetail',
    meta: {
      title: '评估结果'
    },
    component: TestResultDetailView
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      title: '个人中心'
    },
    component: UserCenterView
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: loginView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
